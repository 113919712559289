import { MobileTwoTone, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Divider, Tag, Typography } from "antd";
import { map, values } from "lodash";
import React from "react";
import FirebasePage from "../FirebasePage";
import "./index.css";

const ColorList = ["#7265e6", "#ffbf00"];

export default () => {
	return (
		<FirebasePage
			paths={["contact-us"]}
			renderResult={([contactUsDict]) => {
				const contacts = values(contactUsDict);

				return (
					<Card
						title={<h1>సంప్రదించండి</h1>}
						type="inner"
						className="top-container"
					>
						<div className="master-container">
							{map(contacts, (contact, index) => {
								const color = ColorList[index % ColorList.length];

								return <ContactCard key={`${index}`} {...{ color, contact }} />;
							})}
						</div>
					</Card>
				);
			}}
		/>
	);
};
const { Text } = Typography;

const ContactCard = ({ contact, color }) => {
	const { url, name, center, mobile, mobiles = [mobile], address } = contact;

	return (
		<Card.Grid className="contact-card">
			<Avatar
				style={{ backgroundColor: color, verticalAlign: "middle" }}
				size={120}
				shape="circle"
				src={url}
				icon={<UserOutlined />}
			/>
			<div className="contact-card-right">
				<span className="contact-name">
					<span className="shree">శ్రీ</span>
					{`  ${name}`}
				</span>
				<Divider className="contact-details-separator" />
				<Tag color="green" className="contact-center">
					{center}
				</Tag>
				{mobiles.length > 0 &&
					map(mobiles, (mobile) => (
						<div className="contact-card-number">
							<a
								href={`tel:${mobile}`}
								copyable
								className="contact-card-mobile-number-link"
							>
								<MobileTwoTone style={{ fontSize: "18px" }} />
								<Text copyable className="contact-card-mobile-number">
									{mobile}
								</Text>
							</a>
						</div>
					))}
				{!!address && (
					<>
						<Divider className="contact-details-separator" />
						<Text type="secondary" className="contact-address">
							{address}
						</Text>
					</>
				)}
			</div>
		</Card.Grid>
	);
};
