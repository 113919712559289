import React from "react";
import FirebasePage from "../FirebasePage";
import { values, capitalize } from "lodash";
import { Card, Empty } from "antd";
import ActivityCard from "../ActivityCard";
import "../../constants/styles.css";

export default () => {
  const entry = window.location.pathname.split("/")[2];

  return (
    <FirebasePage
      paths={[`masters-voice/${entry}`]}
      renderResult={([entriesDict]) => {
        const entries = values(entriesDict);

        return (
          <Card title={<h2>{capitalize(entry)}</h2>} className='CardContainer'>
            {entries.length > 0 ? (
              entries.map((entry) => (
                <ActivityCard key={entry.id} activity={entry} />
              ))
            ) : (
              <div style={{ marginLeft: "20px" }}>
                <Empty />
              </div>
            )}
          </Card>
        );
      }}
    />
  );
};
