import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";
import HeaderFooter from "../HeaderFooter";
import "./index.css";

const App = () => {
  return (
    <Router>
      <div className='MasterContainer'>
        <HeaderFooter />
      </div>
    </Router>
  );
};

export default App;
