import { get, replace } from "lodash";

export const transformServerText = (text) => {
	return replace(text, /\\n/g, "\n");
};

export const sectionValue = (data, { key, path, noTransform = false }) => {
	const transformer = noTransform ? (text) => text : transformServerText;

	return transformer(get(data, `${key}.${path}`));
};

export const sectionTitle = (data, { key }) => {
	return sectionValue(data, { key, path: "title" });
};

export const sectionContentValue = (
	data,
	{ key, path = "", noTransform = false }
) => {
	return sectionValue(data, {
		key,
		path: `content${path ? `.${path}` : ""}`,
		noTransform,
	});
};
