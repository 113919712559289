import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import axios from "axios";
import { HOST, FIREBASE_CONFIG } from "../../constants/config";

class Firebase {
  constructor() {
    app.initializeApp(FIREBASE_CONFIG);

    this.auth = app.auth;
    this.db = app.database();
  }
}

export default Firebase;
