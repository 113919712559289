import React, { useEffect, useState } from "react";
import { map } from "lodash";
import { Card } from "antd";
import ReactPlayer from "react-player";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import moment from "moment";
import { Link } from "react-router-dom";
import { CENTERS } from "../../constants/routes";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import useWindowDimensions from "../../utils/useWindowDimensions";

const gridStyle = {
  margin: "20px",
};

export default ({ activity }) => {
  const { width } = useWindowDimensions();

  const [innerGridStyle, setInnerGridStyle] = useState({
    width: "33%",
  });

  useEffect(() => {
    setInnerGridStyle({
      ...gridStyle,
      width: width < 700 ? "100%" : width < 1050 ? "50%" : "33%",
    });
  }, [width]);

  return (
    <>
      <Card.Grid style={gridStyle} style={{ width: "100%" }}>
        <Card
          type='inner'
          title={<h2>{activity.description}</h2>}
          extra={
            <div>
              <div>{moment(activity.date).format("Do MMM YYYY")}</div>
              {activity.centerId && (
                <div>
                  At{" "}
                  <Link to={`${CENTERS}/${activity.centerId}`}>
                    {activity.centerId}
                  </Link>
                </div>
              )}
            </div>
          }
        ></Card>
        {activity.videos && (
          <Card style={gridStyle} type='inner' title='Videos'>
            {map(activity.videos, (video) => (
              <Card.Grid style={innerGridStyle}>
                <ReactPlayer
                  key={video.url}
                  url={video.url}
                  width='100%'
                  height='200px'
                />
              </Card.Grid>
            ))}
          </Card>
        )}
        {activity.audios && (
          <Card style={gridStyle} type='inner' title='Audios'>
            {map(activity.audios, (audio) => (
              <Card.Grid style={{ width: "100%" }}>
                <AudioPlayer
                  src={audio.url}
                  header={audio.description}
                  style={{ width: "100%" }}
                />
              </Card.Grid>
            ))}
          </Card>
        )}
        {activity.images && (
          <Card style={gridStyle} type='inner' title='Images'>
            <ImageGallery
              items={map(activity.images, (image) => ({
                original: image.url,
                thumbnail: image.url,
              }))}
              autoPlay
            />
          </Card>
        )}
      </Card.Grid>
    </>
  );
};
