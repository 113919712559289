import React from "react";
import { Descriptions, Typography, Tag } from "antd";
import { keys, map } from "lodash";

const { Paragraph } = Typography;

export default ({ details }) => {
  return (
    <Descriptions bordered style={{ margin: "20px" }}>
      <Descriptions.Item label='Description'>
        <b>{details.description}</b>
      </Descriptions.Item>
      <Descriptions.Item label='Address'>
        <b>
          <Paragraph copyable>{details.address}</Paragraph>
        </b>
      </Descriptions.Item>
      <Descriptions.Item label='District'>
        <b>{details.district}</b>
      </Descriptions.Item>
      {details.mainActivities && (
        <Descriptions.Item label='Main Activities'>
          <b>
            {map(keys(details.mainActivities), (key) => (
              <Tag color='success'>{key}</Tag>
            ))}
          </b>
        </Descriptions.Item>
      )}
      {details.otherActivities && (
        <Descriptions.Item label='Other Activities'>
          <b>
            {map(keys(details.otherActivities), (key) => (
              <Tag color='orange'>{key}</Tag>
            ))}
          </b>
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};
