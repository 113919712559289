import React from "react";
import { Affix, Tag } from "antd";
import { YoutubeFilled, FacebookFilled } from "@ant-design/icons";

export default () => {
	return (
		<div className="social-icons-container">
			<Tag
				icon={<YoutubeFilled />}
				color="#cd201f"
				className="social-icon-middle"
			>
				<a
					href="https://www.youtube.com/channel/UCH7z9ANzCgfN1pkZ5fhZE9Q"
					target="_blank"
					rel="noopener noreferrer"
				>
					YouTube
				</a>
			</Tag>
			<Tag icon={<FacebookFilled />} color="#3b5999" className="social-icon">
				<a
					href="https://www.facebook.com/profile.php?id=100009575346770"
					target="_blank"
					rel="noopener noreferrer"
				>
					Facebook
				</a>
			</Tag>
		</div>
	);
};
