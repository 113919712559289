import { BackTop, Card, Layout, Menu } from "antd";
import { capitalize, compact, isEmpty, join, last, replace } from "lodash";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, Route, withRouter } from "react-router-dom";
import * as R from "../../constants/routes";
import Activities from "../Activities";
import AllCentersActivities from "../AllCentersActivities";
import AnnualTests from "../AnnualTests";
import BalashikshaSongs from "../BalashikshaSongs";
import Centers from "../Centers";
import Center from "../Centers/center";
import ContactUs from "../ContactUs";
import { useFirebaseFetch } from "../Firebase";
import HomeoServices from "../HomeoServices";
import MastersVoicePage from "../MastersVoice/MastersVoicePage";
import Publications from "../Publications";
import SupadhaSamacharam from "../SupadhaSamacharam";
import Syllabus from "../Syllabus";
import Tours from "../Tours";
import WhatIsSupadha from "../WhatIsSupadha";
import YogaClasses from "../YogaClasses";
import { SocialIconsComponent } from "./footer";
import "./index.css";
import { QuotationsCarousel } from "./QuotationsCarousel";

const { SubMenu } = Menu;
const { Content } = Layout;

const ContentPanel = ({ path, children, exactMatch }) => {
  return (
    <div>
      {(!exactMatch || R.startsWithRoute(path)) && (
        <Content className="PageContent">{children}</Content>
      )}
    </div>
  );
};

const Render = ({ component, atPath, exactMatch = true }) => {
  return (
    <ContentPanel path={atPath} exactMatch={exactMatch}>
      <Route exact={exactMatch} path={atPath} component={component} />
    </ContentPanel>
  );
};

const HeaderFooter = withRouter(({ history }) => {
  const [current, setCurrent] = useState(window.location.pathname);
  const quotationsFirebaseFetchResult = useFirebaseFetch([
    "quotes/footer-quotes",
  ]);
  const { loading, result, error } = quotationsFirebaseFetchResult;
  const quotationsDict = result?.[0];

  const handleClick = (e) => {
    setCurrent(e.key);

    if (e.key) {
      history.push(e.key);
    }
  };

  useEffect(() => {
    document.title = join(
      compact([
        capitalize(replace(last(window.location.pathname.split("/")), "-", "")),
        "సుపథ (The Right Path)",
      ]),
      " - "
    );
  });

  return (
    <Card>
      <BackTop />
      <Card.Grid
        className={`TopmostContainer ${isMobile && "TopmostContainerMobile"}`}
      >
        <div className="Container">
          <div className={`TopContainer ${isMobile && "TopContainerMobile"}`}>
            <Link to="/">
              <div
                className={`TitleLogoContainer ${isMobile && "TitleLogoContainerMobile"
                  }`}
              >
                <span className={`Title ${isMobile && "TitleSubtitleMobile"}`}>
                  సుపథ
                </span>
                <span
                  className={`Subtitle ${isMobile && "TitleSubtitleMobile"}`}
                >
                  (The Right Path)
                </span>
                <SocialIconsComponent />
              </div>
              <div className={`LogoRow ${isMobile && "LogoRowMobile"}`}>
                <img
                  alt=""
                  src={require("../../resources/logo.ico")}
                  className={`LogoImage ${isMobile && "LogoImageMobile"}`}
                />
              </div>
            </Link>
            <div className="MenuContainer">
              <Menu
                className="Menu"
                onClick={handleClick}
                selectedKeys={[current]}
                mode="horizontal"
              >
                {/* <Menu.Item key={R.ACTIVITIES}>కార్యక్రమాలు</Menu.Item> */}

                {/* <Menu.Item key={R.CENTERS}>బృందం</Menu.Item> */}

                {/* <SubMenu title={"సుపథ ప్రతిభా పరీక్షలు"}>
                    <Menu.Item key={R.ANNUAL_TESTS}>
                      వార్షిక ప్రతిభా పరీక్షలు
                    </Menu.Item>
                    <Menu.Item key={R.SYLLABUS}>సిలబస్</Menu.Item>
                  </SubMenu> */}

                <Menu.Item key={"/"}>సుపథ అనగా</Menu.Item>

                {/* <SubMenu title="భారతీయ బాలశిక్ష" key={R.BHARATEEYA_BALASHIKSHA}>
									<Menu.Item key={`${R.BHARATEEYA_BALASHIKSHA}/${R.BB_PART1}`}>
										మొదటి భాగం
									</Menu.Item>
									<Menu.Item key={`${R.BHARATEEYA_BALASHIKSHA}/${R.BB_PART2}`}>
										రెండవ భాగం
									</Menu.Item>
								</SubMenu> */}

                <Menu.Item key={R.SYLLABUS}>
                  ప్రతిభా పరీక్షల పాఠ్యాంశాలు
                </Menu.Item>

                <Menu.Item key={R.S_SAMACHARAM}>సుపథ సమాచారం</Menu.Item>

                <Menu.Item key={R.PUBLICATIONS} onTitleClick={handleClick}>
                  ప్రచురణలు
                </Menu.Item>

                {/* <SubMenu title="ప్రసంగాలు">
                    <Menu.Item key={R.V_BHARATAM}>Mahabharatam</Menu.Item>
                    <Menu.Item key={R.V_BHAGAVATAM}>MahaBhagavatam</Menu.Item>
                    <Menu.Item key={R.V_RAMAYANAM}>Ramayanam</Menu.Item>
                    <Menu.Item key={R.V_OS}>Occult Sciences</Menu.Item>
                    <Menu.Item key={R.V_PYS}>Patanjali Yoga Sutras</Menu.Item>
                    <Menu.Item key={R.V_OTHERS}>Others</Menu.Item>
                  </SubMenu> */}

                {/* <Menu.Item key={R.YOGA_CLASSES}>యోగా తరగతులు</Menu.Item> */}

                {/* <Menu.Item key={R.TOURS}>యాత్రలు</Menu.Item> */}

                {/* <SubMenu title="WTT VZM">
                    <Menu.Item key={R.YEARLY_ACTIVITIES}>
                      వార్షిక కార్యక్రమాలు
                    </Menu.Item>
                    <Menu.Item key={R.HOMEO_SERVICES}>హోమియో సేవలు</Menu.Item>
                  </SubMenu> */}

                <Menu.Item key={R.CONTACT_US}>సంప్రదించండి</Menu.Item>
              </Menu>
            </div>

            <div className="ChildContainer">
              <Render component={WhatIsSupadha} atPath="/" />

              {/* <Render
								component={BalashikshaSongs}
								atPath={`${R.BHARATEEYA_BALASHIKSHA}/${R.BB_PART1}`}
							/>
							<Render
								component={BalashikshaSongs}
								atPath={`${R.BHARATEEYA_BALASHIKSHA}/${R.BB_PART2}`}
							/> */}

              <Render
                component={AllCentersActivities}
                atPath={R.ACTIVITIES}
                exactMatch={false}
              />
              <Render component={AnnualTests} atPath={R.ANNUAL_TESTS} />
              <Render
                component={Publications}
                atPath={R.PUBLICATIONS}
                exactMatch={false}
              />
              <Render
                component={Syllabus}
                atPath={R.SYLLABUS}
                exactMatch={false}
              />
              <Render
                component={MastersVoicePage}
                atPath={`${R.VOICE}/bharatam`}
              />
              <Render
                component={MastersVoicePage}
                atPath={`${R.VOICE}/bhagavatam`}
              />
              <Render
                component={MastersVoicePage}
                atPath={`${R.VOICE}/ramayanam`}
              />
              <Render
                component={MastersVoicePage}
                atPath={`${R.VOICE}/occult-sciences`}
              />
              <Render
                component={MastersVoicePage}
                atPath={`${R.VOICE}/patanjali-ys`}
              />
              <Render
                component={MastersVoicePage}
                atPath={`${R.VOICE}/others`}
              />
              <Render component={SupadhaSamacharam} atPath={R.S_SAMACHARAM} />
              <Render component={Centers} atPath={R.CENTERS} />
              <Render
                component={Center}
                atPath={`${R.CENTERS}/:id`}
                exactMatch={false}
              />
              <Render component={YogaClasses} atPath={R.YOGA_CLASSES} />
              <Render component={Activities} atPath={R.YEARLY_ACTIVITIES} />
              <Render component={HomeoServices} atPath={R.HOMEO_SERVICES} />
              <Render component={Tours} atPath={R.TOURS} exactMatch={false} />
              <Render component={ContactUs} atPath={R.CONTACT_US} />
            </div>
            <div className="FooterContainer">
              {!loading && !error && !isEmpty(quotationsDict) && (
                <QuotationsCarousel {...{ quotationsDict }} />
              )}
              <div className="copyrights-text">
                Copyright © Supadha.org. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </Card.Grid>
    </Card>
  );
});

export default HeaderFooter;
