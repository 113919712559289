import React from "react";
import { isMobile } from "react-device-detect";
import SocialIcons from "../WhatIsSupadha/SocialIcons";
import "./index.css";

export const SocialIconsComponent = () => {
	return (
		<div className={`Footer ${isMobile && "FooterMobile"}`}>
			<SocialIcons />
		</div>
	);
};
