import React from "react";
import FirebasePage from "../FirebasePage";
import { Card } from "antd";
// import "./index.css";

export default () => {
  return (
    <FirebasePage
      paths={["events-data", "event-categories"]}
      renderResult={([activitiesDict, eventCategoriesDict]) => {
        return <Card title='హోమియో సేవలు' />;
      }}
    />
  );
};
