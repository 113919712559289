import { DownloadOutlined, ReadOutlined } from "@ant-design/icons";
import { Button, Card, Modal } from "antd";
import { chain, filter, map, orderBy, values } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "../../constants/styles.css";
import PDFViewer from "../../utils/PDFViewer";
import { Empty } from "../Common/Empty";
import FirebasePage from "../FirebasePage";

const SyllabusCard = ({ syllabus, allowDownload }) => {
	const date = moment(syllabus.year, "YYYY");
	const { showBookTitle, bookTitle } = syllabus;

	const [showingBookViewer, setShowingBookViewer] = useState(false);

	const hideBookViewer = () => {
		setShowingBookViewer(false);
	};

	const showBookViewer = () => {
		setShowingBookViewer(true);
	};

	return (
		<Card.Grid style={{ width: "100%" }} key={syllabus.id}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<img alt="" style={{ width: 50 }} src="/pdf-icon.png" />
					<div style={{ marginLeft: "20px" }}>
						<h3>
							{date.format("YYYY")} పాఠ్యాంశము
							{showBookTitle ? ` - ${bookTitle}` : ""}
						</h3>
					</div>
				</div>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<h3>
						<Button
							type="default"
							shape="round"
							icon={<ReadOutlined />}
							size="large"
							onClick={() => showBookViewer()}
						>
							View
						</Button>
					</h3>
					{allowDownload && (
						<h3>
							<Button
								type="primary"
								shape="round"
								title="Download Syllabus"
								icon={<DownloadOutlined />}
								size="large"
								onClick={() => window.open(syllabus.url, "_blank")}
								style={{ marginLeft: "20px" }}
							>
								Download
							</Button>
						</h3>
					)}
				</div>
			</div>
			{syllabus.audios && (
				<Card title="Audios" type="inner" style={{ marginTop: "20px" }}>
					{map(syllabus.audios, (audio, index) => (
						<Card.Grid key={index} style={{ width: "100%" }}>
							<AudioPlayer src={audio.url} style={{ width: "100%" }} />
						</Card.Grid>
					))}
				</Card>
			)}
			{showingBookViewer && (
				<Modal
					visible={showingBookViewer}
					onCancel={hideBookViewer}
					width={"75%"}
					style={{ top: 20 }}
					footer={null}
				>
					<PDFViewer url={syllabus.url} />
				</Modal>
			)}
		</Card.Grid>
	);
};

const SyllabusContainer = ({ syllabusDict }) => {
	const [tabKey, setTabKey] = useState(null);

	const items = orderBy(values(syllabusDict), "year", "desc");
	const latestYear = chain(items)
		.map((item) => item.year)
		.max()
		.value();
	const latestItems = filter(items, (item) => item.year === latestYear);
	const olderItems = filter(items, (item) => item.year !== latestYear);

	const tabsList = [
		{
			key: latestItems.length
				? `${latestItems[0].year} Syllabus`
				: "Latest Syllabus",
			tab: latestItems.length
				? `${latestItems[0].year} పాఠ్యాంశము`
				: "పాఠ్యాంశము",
		},
		{
			key: "Old Syllabuses",
			tab: "పాత పాఠ్యాంశాలు",
		},
	];

	const tabsContent = {
		[latestItems.length
			? `${latestItems[0].year} Syllabus`
			: "Latest Syllabus"]: map(latestItems, (item) => (
			<SyllabusCard syllabus={item} allowDownload />
		)),
		"Old Syllabuses": map(olderItems, (item) => (
			<SyllabusCard syllabus={item} allowDownload={false} />
		)),
	};

	useEffect(() => {
		setTabKey(tabsList[0].key);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		tabKey && (
			<Card
				type="inner"
				className="CardContainer"
				title={<h1>ప్రతిభా పరీక్షల పాఠ్యాంశాలు</h1>}
				tabList={tabsList}
				activeTabKey={tabKey}
				onTabChange={(key) => setTabKey(key)}
			>
				{tabsContent[tabKey].length === 0 ? <Empty /> : tabsContent[tabKey]}
			</Card>
		)
	);
};

export default () => {
	return (
		<FirebasePage
			paths={["syllabus"]}
			renderResult={([syllabusDict]) => (
				<SyllabusContainer syllabusDict={syllabusDict} />
			)}
		/>
	);
};
