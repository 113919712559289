import FirebaseContext, { withFirebase } from "./context";
import Firebase from "./firebase";
import { useState, useEffect } from "react";
import { map } from "lodash";

const firebase = new Firebase();

export default firebase;

export { FirebaseContext, withFirebase };

export const useFirebaseFetch = (paths) => {
  const [state, setState] = useState({
    loading: true,
    result: null,
    error: null,
  });

  useEffect(() => {
    setState({ ...state, loading: true });

    const promises = Promise.all(
      map(paths, (path) => firebase.db.ref(path).once("value"))
    );

    promises
      .then((result) => {
        setState({
          loading: false,
          result: map(result, (elem) => elem.val()),
        });
      })
      .catch((error) => {
        setState({
          loading: false,
          error,
        });
      });
  }, []);

  return state;
};
