import React, { useState } from "react";
import FirebasePage from "../FirebasePage";
import { values, map, filter, flatten, orderBy, uniq, sortBy } from "lodash";
import { DatePicker, Radio, Select, Alert, Card, Empty, Button } from "antd";
import moment from "moment";
import "./index.css";
import ActivityCard from "../ActivityCard";

const { RangePicker } = DatePicker;
const { Option } = Select;

const TYPE_ALL = "All";
const TYPE_SPECIFIC = "Specific";

const MODE_INFO = "info";
const MODE_CONTENT = "content";

const ActivitiesInfoPage = ({ activityTypes, onClickActivity }) => {
  return (
    <Card>
      <div style={{ margin: 20 }}>
        <div>
          సుపథ ఆధ్వర్యంలో క్రింది విభాగాలలో కార్యక్రమాలు జరుపబడుతున్నాయి.
          కార్యక్రమాల వివరాలు తెలుసుకోవడానికి ఏదైనా విభాగం పైన click చేయండి.
        </div>
        <div style={{ margin: 20 }}>
          <ol>
            {map(sortBy(activityTypes), (category) => (
              <li key={category}>
                <Button type="link" onClick={() => onClickActivity(category)}>
                  {category}
                </Button>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </Card>
  );
};

export default () => {
  const [centerFilterType, setCenterFilterType] = useState(TYPE_ALL);
  const [yearFilterType, setYearFilterType] = useState(TYPE_ALL);
  const [activityFilterType, setActivityFilterType] = useState(TYPE_ALL);
  const [activityType, setActivityType] = useState(null);
  const [centerId, setCenterId] = useState(null);
  const [yearRange, setYearRange] = useState([moment(), moment()]);
  const [mode, setMode] = useState(MODE_INFO);

  const getBannerMessage = (centers) => {
    if (centerFilterType === TYPE_SPECIFIC && !centerId) {
      return "Choose your center";
    }

    if (activityFilterType === TYPE_SPECIFIC && !activityType) {
      return "Choose activity category";
    }

    return `Showing ${
      centerFilterType === TYPE_ALL
        ? "all centers'"
        : `${filter(centers, (c) => c.id === centerId)[0].name} center`
    } activities for ${
      yearFilterType === TYPE_ALL
        ? "all years"
        : yearRange[0].year() === yearRange[1].year()
        ? `year ${moment(yearRange[0]).year()}`
        : `${yearRange[0].year()} - ${yearRange[1].year()}`
    } under ${
      activityFilterType === TYPE_ALL ? "all categories" : activityType
    } category`;
  };

  const onClickActivity = (category) => {
    setMode(MODE_CONTENT);
    setActivityFilterType(TYPE_SPECIFIC);
    setActivityType(category);
  };

  const onYearRangeChange = (values) => {
    setYearRange(values);
  };

  const onCenterFilterTypeChange = (event) => {
    setCenterFilterType(event.target.value);
  };

  const onYearFilterTypeChange = (event) => {
    setYearFilterType(event.target.value);
  };

  const onActivityFilterTypeChange = (event) => {
    setActivityFilterType(event.target.value);
  };

  const onCenterChange = (center) => {
    setCenterId(center);
  };

  const onActivityTypeChange = (type) => {
    setActivityType(type);
  };

  const filterActivities = (activities) => {
    let filtered = [];

    if (centerFilterType === TYPE_ALL) {
      filtered = activities;
    } else {
      if (centerId) {
        filtered = filter(activities, (a) => a.centerId === centerId);
      } else {
        filtered = activities;
      }
    }

    if (yearFilterType === TYPE_SPECIFIC) {
      filtered = filter(filtered, (a) => {
        const activityYear = moment(a.date).year();
        return (
          activityYear >= yearRange[0].year() &&
          activityYear <= yearRange[1].year()
        );
      });
    }

    return filtered;
  };

  return (
    <FirebasePage
      paths={["center-activities", "centers"]}
      renderResult={([activitiesDict, centersDict]) => {
        const activities = orderBy(
          flatten(map(values(activitiesDict), (dict) => values(dict))),
          "date",
          "desc"
        );
        const centers = values(centersDict);

        const activityTypes = uniq(map(activities, (c) => c.type));

        const filteredActivities = filterActivities(activities);

        const centerFilterSelector = (
          <Radio.Group
            value={centerFilterType}
            onChange={onCenterFilterTypeChange}
          >
            <Radio.Button value={TYPE_ALL}>All Centers</Radio.Button>
            <Radio.Button value={TYPE_SPECIFIC}>Specific Center</Radio.Button>
          </Radio.Group>
        );

        const yearRangeTypeSelector = (
          <Radio.Group value={yearFilterType} onChange={onYearFilterTypeChange}>
            <Radio.Button value={TYPE_ALL}>All Years</Radio.Button>
            <Radio.Button value={TYPE_SPECIFIC}>Year Range</Radio.Button>
          </Radio.Group>
        );

        const activityFilterTypeSelector = (
          <Radio.Group
            value={activityFilterType}
            onChange={onActivityFilterTypeChange}
          >
            <Radio.Button value={TYPE_ALL}>All Categories</Radio.Button>
            <Radio.Button value={TYPE_SPECIFIC}>Specific Category</Radio.Button>
          </Radio.Group>
        );

        const yearRangePicker = (
          <RangePicker
            picker="year"
            defaultOpen
            defaultValue={yearRange}
            onChange={onYearRangeChange}
          />
        );

        const centerPicker = (
          <Select
            defaultOpen
            style={{ width: 200 }}
            placeholder="e.g. Lakkavarapu Kota"
            optionFilterProp="children"
            onChange={onCenterChange}
            filterOption={(input, option) =>
              map(option.children, (c) => c.name)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {map(centers, (center) => (
              <Option key={center.id} value={center.id}>
                {center.name}
              </Option>
            ))}
          </Select>
        );

        const activityTypePicker = (
          <Select
            defaultOpen
            style={{ width: 200 }}
            placeholder="e.g. Sunday Supadha"
            optionFilterProp="children"
            value={activityType}
            onChange={onActivityTypeChange}
            filterOption={(input, option) =>
              map(option.children, (c) => c.name)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {map(activityTypes, (type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        );

        return (
          <Card
            type="inner"
            title={<h2>కార్యక్రమాలు</h2>}
            className="container"
          >
            {mode === MODE_INFO ? (
              <ActivitiesInfoPage
                activityTypes={activityTypes}
                onClickActivity={onClickActivity}
              />
            ) : (
              <>
                <Card.Grid type="inner" className="inner-container">
                  <div className="type-container">
                    {centerFilterSelector}
                    {centerFilterType === TYPE_SPECIFIC && centerPicker}
                  </div>
                  <div className="type-container">
                    {yearRangeTypeSelector}
                    {yearFilterType === TYPE_SPECIFIC && yearRangePicker}
                  </div>
                  <div className="type-container">
                    {activityFilterTypeSelector}
                    {activityFilterType === TYPE_SPECIFIC && activityTypePicker}
                  </div>
                  <div className="banner-message">
                    <Alert
                      message={<h3>{getBannerMessage(centers)}</h3>}
                      type="info"
                      showIcon
                    />
                  </div>
                </Card.Grid>
                <Card.Grid style={{ width: "100%" }}>
                  {filteredActivities.length === 0 ? (
                    <Empty />
                  ) : (
                    map(filteredActivities, (activity) => (
                      <ActivityCard key={activity.id} activity={activity} />
                    ))
                  )}
                </Card.Grid>
              </>
            )}
          </Card>
        );
      }}
    />
  );
};
