import React, { useState } from "react";
import FirebasePage from "../FirebasePage";
import { keys, values, map, filter } from "lodash";
import { DatePicker, Radio, Select, Alert, Card, Empty } from "antd";
import moment from "moment";
import "./index.css";
import ActivityCard from "../ActivityCard";

const { RangePicker } = DatePicker;
const { Option } = Select;

const YEAR_RANGE = "year-range";
const CATEGORY = "category";
const ALL = "all";

export default () => {
  const [type, setType] = useState(ALL);
  const [yearRange, setYearRange] = useState([moment(), moment()]);
  const [category, setCategory] = useState(null);

  const onTypeChange = (e) => {
    const type = e.target.value;
    setType(type);
  };

  const getBannerMessage = () => {
    if (type === YEAR_RANGE) {
      return `Showing Activities For ${yearRange[0].year()}${
        yearRange[0].year() !== yearRange[1].year()
          ? ` - ${yearRange[1].year()}`
          : ""
      }`;
    } else if (type === CATEGORY) {
      if (category) {
        return `Showing Activities For ${category}`;
      } else {
        return "Please Choose Category";
      }
    } else if (type === ALL) {
      return "Showing All Activities";
    }
  };

  const getTypePickerMessage = () => {
    if (type === YEAR_RANGE) {
      return "Please Choose Year Range ";
    } else if (type === CATEGORY) {
      return "Please Choose Category ";
    } else if (type === ALL) {
      return null;
    }
  };

  const onYearRangeChange = (values) => {
    setYearRange(values);
  };

  const onCategoryChange = (value) => {
    setCategory(value);
  };

  const filterActivities = (activities) => {
    if (type === ALL) {
      return activities;
    } else if (type === YEAR_RANGE) {
      return filter(activities, (activity) => {
        const activityYear = moment(activity.date).year();
        return (
          activityYear >= yearRange[0].year() &&
          activityYear <= yearRange[1].year()
        );
      });
    } else if (type === CATEGORY) {
      if (category) {
        return filter(activities, (activity) => activity.category === category);
      } else {
        return activities;
      }
    }

    return activities;
  };

  return (
    <FirebasePage
      paths={["events-data", "event-categories"]}
      renderResult={([activitiesDict, eventCategoriesDict]) => {
        const activities = values(activitiesDict);
        const categories = keys(eventCategoriesDict);
        const filteredActivities = filterActivities(activities);

        const categoriesSelector = (
          <Radio.Group value={type} onChange={onTypeChange}>
            <Radio.Button value={ALL}>All Activities</Radio.Button>
            <Radio.Button value={CATEGORY}>By Category</Radio.Button>
            <Radio.Button value={YEAR_RANGE}>By Year Range</Radio.Button>
          </Radio.Group>
        );

        const yearRangePicker = (
          <RangePicker
            picker='year'
            defaultOpen
            defaultValue={yearRange}
            onChange={onYearRangeChange}
          />
        );
        const categoryPicker = (
          <Select
            showSearch
            defaultOpen
            style={{ width: 200 }}
            placeholder='e.g. Sri Rama Navami'
            optionFilterProp='children'
            onChange={onCategoryChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {map(categories, (category) => (
              <Option key={category} value={category}>
                {category}
              </Option>
            ))}
          </Select>
        );

        return (
          <Card
            type='inner'
            title={<h2>Yearly Activities</h2>}
            className='container'
          >
            <Card.Grid type='inner' className='inner-container'>
              <div className='type-container'>
                {categoriesSelector}
                <div className='types-holder'>
                  {getTypePickerMessage()}
                  {type === YEAR_RANGE && yearRangePicker}
                  {type === CATEGORY && categoryPicker}
                </div>
              </div>
              <div className='banner-message'>
                <Alert
                  message={<h3>{getBannerMessage()}</h3>}
                  type='info'
                  showIcon
                />
              </div>
            </Card.Grid>
            <Card.Grid style={{ width: "100%" }}>
              {filteredActivities.length === 0 ? (
                <Empty />
              ) : (
                map(filteredActivities, (activity) => (
                  <ActivityCard key={activity.id} activity={activity} />
                ))
              )}
            </Card.Grid>
          </Card>
        );
      }}
    />
  );
};
