import React, { useState } from "react";
import FirebasePage from "../FirebasePage";
import { values, orderBy } from "lodash";
import { Card } from "antd";

import CenterDetails from "./centerDetails";
import CenterActivities from "./centerActivities";

import "../../constants/styles.css";

export default ({ match: { params } }) => {
  const id = params.id;

  console.log(`CenterId: `, id);

  const [tabKey, setTabKey] = useState("details");

  return (
    <FirebasePage
      paths={[`centers/${id}`, `center-activities/${id}`]}
      renderResult={([center, activitiesDict]) => {
        const activities = orderBy(values(activitiesDict), "date", "desc");

        const tabsList = [
          {
            key: "details",
            tab: "Center Details",
          },
          {
            key: "activities",
            tab: "Center Activities",
          },
        ];

        const tabsContent = {
          details: <CenterDetails details={center} />,
          activities: <CenterActivities activities={activities} />,
        };

        return (
          <Card
            type='inner'
            className='CardContainer'
            title={<h2>{center.name}</h2>}
            tabList={tabsList}
            activeTabKey={tabKey}
            onTabChange={(key) => {
              setTabKey(key);
            }}
          >
            {tabsContent[tabKey]}
          </Card>
        );
      }}
    />
  );
};
