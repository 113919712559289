import React from "react";
import { map } from "lodash";
import { Card } from "antd";
import ActivityCard from "../ActivityCard";

export default ({ activities }) => {
  return (
    <Card>
      {map(activities, (activity) => (
        <ActivityCard key={activity.id} activity={activity} />
      ))}
    </Card>
  );
};
