import { Alert, Card } from "antd";
import { filter, get, isEmpty, map, values } from "lodash";
import moment from "moment";
import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { sectionContentValue, sectionTitle } from "../../utils/textUtils";
import { ContentSection } from "../ContentSection";
import FirebasePage from "../FirebasePage";
import "./index.css";

const WhatIsSupadha = () => {
	return (
		<FirebasePage
			paths={["home-page", "cover-images", "events"]}
			renderResult={([homePageContent, imagesDict, eventsDict]) => {
				const images = values(imagesDict);
				const upcomingEvents = filter(
					values(eventsDict),
					(event) => event.date > moment().hour(0).minute(0).second(0).valueOf()
				);
				const { "content-sections": contentSections } = homePageContent || {};
				const shodashiContent = get(homePageContent, "banners.shodashi", {});
				const { title, subtitle, url: shodashiUrl } = shodashiContent;

				return (
					<Card className="carousel-container">
						{!isEmpty(shodashiContent) && (
							<Alert
								message={<div className="shodashi-message">{title}</div>}
								description={
									<div>
										{subtitle}
										<a
											href={shodashiUrl}
											alt=""
											className="shodashi-url"
											target="_blank"
											rel="noopener noreferrer"
										>
											వీక్షించండి
										</a>
									</div>
								}
								type="success"
								className="shodashi-container"
							/>
						)}
						{/* <ImageGallery
              items={map(images, (image) => ({
                original: image.url,
                thumbnail: image.url,
                description: image.description,
                originalClass: "carousel-image",
              }))}
              showBullets
              additionalClass='carousel-container'
              disableArrowKeys={true}
              thumbnailPosition='right'
              showThumbnails={true}
              infinite={true}
              slideOnThumbnailOver={true}
              showPlayButton={false}
              showNav={false}
              showFullscreenButton={false}
              autoPlay
            ></ImageGallery> */}
						<div className="data-container">
							{/* <div className="content2">
                <UpcomingEvents upcomingEvents={upcomingEvents} />
              </div> */}

							<SectionRow>
								<ContentSection
									title={sectionTitle(contentSections, { key: "supadha" })}
									content={sectionContentValue(contentSections, {
										key: "supadha",
									})}
								/>
							</SectionRow>

							<SectionRow>
								<ContentSection
									title={sectionTitle(contentSections, { key: "uddeshyam" })}
									content={
										<>
											<div>
												{sectionContentValue(contentSections, {
													key: "uddeshyam",
													path: "line1",
												})}
											</div>
											<div style={{ marginTop: 20 }}>
												<i>
													"
													{sectionContentValue(contentSections, {
														key: "uddeshyam",
														path: "line2.quote",
													})}
													"
												</i>{" "}
												{sectionContentValue(contentSections, {
													key: "uddeshyam",
													path: "line2.quoteSuffix",
												})}
											</div>
										</>
									}
								/>
								<ContentSection
									title={sectionTitle(contentSections, {
										key: "vyavastheekaranam",
									})}
									content={sectionContentValue(contentSections, {
										key: "vyavastheekaranam",
									})}
								/>
							</SectionRow>
							<SectionRow>
								<ContentSection
									title={sectionTitle(contentSections, { key: "karyakramalu" })}
									content={
										<div style={{ display: "flex", justifyContent: "center" }}>
											<ul style={{ textAlign: "left" }}>
												{map(
													sectionContentValue(contentSections, {
														key: "karyakramalu",
														noTransform: true,
													}),
													(karyakramam) => {
														return <li key={karyakramam}>{karyakramam}</li>;
													}
												)}
											</ul>
										</div>
									}
								/>
							</SectionRow>
							<SectionRow>
								<ContentSection
									title={sectionTitle(contentSections, {
										key: "vyavasthapakulu",
									})}
									content={sectionContentValue(contentSections, {
										key: "vyavasthapakulu",
									})}
								/>
							</SectionRow>
						</div>
					</Card>
				);
			}}
		/>
	);
};

const SectionRow = ({ style = {}, classNames = [], children }) => {
	const classNamesDefault = ["content-section-row"];

	return (
		<div
			style={style}
			className={[...classNamesDefault, ...classNames].join(" ")}
		>
			{children}
		</div>
	);
};
export default WhatIsSupadha;
