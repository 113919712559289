import React from "react";
import { Card, Divider } from "antd";
import { useEffect, useState } from "react";
import "./ContentSection.css";

export const ContentSection = ({ title, content, style }) => {
	const [legendStyle, setStyle] = useState({
		display: "flex",
		flexDirection: "column",
		borderRadius: "10px",
		width: "48%",
		margin: 4,
		padding: 0,
		backgroundColor: "rgb(228, 247, 254)",
		borderWidth: 1,
		borderColor: "lightblue",
	});

	const handleResize = () => {
		if (window.innerWidth < 1024) {
			setStyle({
				...legendStyle,
				width: "100%",
			});
		} else {
			setStyle({
				...legendStyle,
				width: "48%",
			});
		}
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card.Grid style={{ ...legendStyle, ...style }}>
			<div className="header">
				<h2>{title}</h2>
			</div>
			<div className="content">
				<h3 style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>
					{content}
				</h3>
			</div>
		</Card.Grid>
	);
};
