import React, { useState } from "react";
import FirebasePage from "../FirebasePage";
import { orderBy, values, map, keys, mapValues, first, sortBy } from "lodash";
import ActivityCard from "../ActivityCard";
import { Card } from "antd";
import "../../constants/styles.css";

const YogaClass = ({ yogaClass }) => {
  const { content } = yogaClass;

  const tabsList = map(sortBy(keys(content)), (key) => ({
    key: key,
    tab: key,
  }));
  const tabsContent = mapValues(content, (data) => (
    <ActivityCard activity={data} />
  ));

  const [tabKey, setTabKey] = useState(first(sortBy(keys(content))));

  return (
    <Card.Grid style={{ width: "100%" }}>
      <Card
        type="inner"
        className="CardContainer"
        title={<h2>{yogaClass.description}</h2>}
        tabList={tabsList}
        activeTabKey={tabKey}
        onTabChange={(key) => {
          setTabKey(key);
        }}
      >
        {tabsContent[tabKey]}
      </Card>
    </Card.Grid>
  );
};

export default () => {
  return (
    <FirebasePage
      paths={["yoga-classes"]}
      renderResult={([yogaClassesDict]) => {
        const yogaClasses = orderBy(values(yogaClassesDict), "date", "desc");

        return (
          <Card title={<h1>వార్షిక ప్రతిభా పరీక్షలు</h1>}>
            {map(yogaClasses, (yogaClass) => (
              <YogaClass yogaClass={yogaClass} />
            ))}
          </Card>
        );
      }}
    ></FirebasePage>
  );
};
