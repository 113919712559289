import React from "react";
import FirebasePage from "../FirebasePage";
import { values, orderBy, map, chain, uniqBy, forEach, filter } from "lodash";
import { Empty, Button, Timeline, Card } from "antd";
import moment from "moment";
import "../../constants/styles.css";
import { DownloadOutlined } from "@ant-design/icons";
import { FlagTwoTone } from "@material-ui/icons";
import "./index.css";

export default () => {
	return (
		<FirebasePage
			paths={["supadha-samacharam"]}
			renderResult={([samacharamDict]) => {
				const items = orderBy(values(samacharamDict), "month", "desc");
				const transformedItems = map(items, (item) => {
					const monthMoment = moment(item.month);
					return { ...item, date: monthMoment, year: `${monthMoment.year()}` };
				});
				const uniqueYears = chain(transformedItems)
					.map("year")
					.uniq()
					.orderBy("desc")
					.value();
				const finalItems = [];
				forEach(uniqueYears, (year) => {
					finalItems.push({
						isSection: true,
						title: year,
						id: year,
					});

					finalItems.push(
						...filter(transformedItems, (item) => `${item.year}` === year)
					);
				});

				return (
					<Card
						title={<h1>సుపథ సమాచారం</h1>}
						type="inner"
						className="CardContainer"
					>
						<Timeline mode="left" style={{ padding: 24 }}>
							{finalItems.length > 0 ? (
								map(finalItems, (item) => {
									const { title, isSection = false, month } = item;

									if (isSection) {
										return (
											<Timeline.Item
												style={{ width: "100%" }}
												key={item.id}
												dot={<FlagTwoTone />}
											>
												<span className="year-title">{title}</span>
											</Timeline.Item>
										);
									}
									const date = moment(month, "YYYY-MM");

									return (
										<Timeline.Item key={item.id}>
											<Card style={{ borderWidth: 0, padding: 0 }}>
												<Card.Grid
													className="samacharam-item"
													style={{
														borderRadius: 6,
														borderWidth: 1,
													}}
												>
													<div
														style={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
														}}
													>
														<img
															alt=""
															style={{ width: 50 }}
															src="/pdf-icon.png"
														/>
														<div style={{ marginLeft: "20px" }}>
															<div className="samacharam-item-title">
																{title}
															</div>
															<div className="samacharam-item-subtitle">
																{date.year()}
															</div>
														</div>
													</div>
													<h3>
														<Button
															type="primary"
															shape="round"
															size="large"
															icon={<DownloadOutlined />}
															onClick={() => window.open(item.url, "_blank")}
														>
															Download
														</Button>
													</h3>
												</Card.Grid>
											</Card>
										</Timeline.Item>
									);
								})
							) : (
								<Empty />
							)}
						</Timeline>
					</Card>
				);
			}}
		/>
	);
};
