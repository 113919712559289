export const WHAT_IS_SUPADHA = "/what-is-supadha";
export const PUBLICATIONS = "/publications";
export const SYLLABUS = "/syllabus";
export const ANNUAL_TESTS = "/annual-tests";

export const BHARATEEYA_BALASHIKSHA = "/bharateeya-balashiksha";
export const BB_PART1 = "part1";
export const BB_PART2 = "part2";

export const VOICE = "/voice";

export const V_BHARATAM = "/voice/bharatam";
export const V_BHAGAVATAM = "/voice/bhagavatam";
export const V_RAMAYANAM = "/voice/ramayanam";
export const V_OS = "/voice/occult-sciences";
export const V_PYS = "/voice/patanjali-yoga-sutras";
export const V_OTHERS = "/voice/others";

export const S_SAMACHARAM = "/samacharam";

export const CENTERS = "/centers";

export const YOGA_CLASSES = "/yoga-classes";

export const YEARLY_ACTIVITIES = "/yearly-events";

export const HOMEO_SERVICES = "/homeo-services";

export const ACTIVITIES = "/activities";

export const TOURS = "/tours";

export const CONTACT_US = "/contact-us";

export const startsWithRoute = (path) => {
	if (!path) {
		return false;
	}

	const firstChar = path.charAt(0);
	if (firstChar !== "/") {
		path = `/${path}`;
	}

	return window.location.pathname.startsWith(path);
};
