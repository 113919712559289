import React from "react";
import { Card, Typography, Button } from "antd";
import FirebasePage from "../FirebasePage";
import { map, values } from "lodash";
import { Link } from "react-router-dom";
import "../../constants/styles.css";

const { Paragraph } = Typography;

export default () => {
  return (
    <FirebasePage
      paths={["centers"]}
      renderResult={([centersDict]) => {
        const centers = values(centersDict);

        return (
          <Card
            className="CardContainer"
            type="inner"
            title={<h2>Our Centers</h2>}
          >
            <div className="GridContainer">
              {map(centers, (center, index) => (
                <Link to={`centers/${center.id}`}>
                  <Card
                    key={center.id}
                    type="inner"
                    className={["One", "Two"][index % 2]}
                    title={
                      <h3>
                        <Button type="link">{center.name}</Button>
                      </h3>
                    }
                  ></Card>
                </Link>
              ))}
            </div>
          </Card>
        );
      }}
    />
  );
};
