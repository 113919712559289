import React, { useEffect } from "react";
import { useFirebaseFetch } from "../Firebase";

import { Spin, notification } from "antd";

import "./index.css";
import { LOADER_MESSAGE } from "../../constants/config";

export default ({ paths, renderResult }) => {
	const resource = useFirebaseFetch(paths);

	useEffect(() => {}, []);

	if (resource.loading) {
		return (
			<div className="LoaderContainer">
				<Spin size="large" tip={LOADER_MESSAGE} />
			</div>
		);
	}

	if (resource.error) {
		notification.info({
			message: `Error: ${resource.error.message}`,
			placement: "topRight",
		});
		return <></>;
	}

	return <div className="Container">{renderResult(resource.result)}</div>;
};
