import { Carousel } from "antd";
import { map, values } from "lodash";
import React from "react";
import "./index.css";

export const QuotationsCarousel = ({ quotationsDict, className }) => {
	const quotations = values(quotationsDict);

	const contentStyle = {
		height: "160px",
		color: "#fff",
		lineHeight: "160px",
		textAlign: "center",
		background: "#364d79",
	};
	return (
		<div className={`master-container ${className || ""}`}>
			<Carousel autoplay arrows className="carousel">
				{/* <div>
					<h3 style={contentStyle}>1</h3>
				</div>
				<div>
					<h3 style={contentStyle}>2</h3>
				</div>
				<div>
					<h3 style={contentStyle}>3</h3>
				</div>
				<div>
					<h3 style={contentStyle}>4</h3>
				</div> */}
				{map(quotations, (quote) => {
					const { text = "", quoteBy } = quote;
					const { name, url } = quoteBy;

					return (
						<div className={`quote-container`} key={text}>
							<div className="quote-text-container">
								{/* <div className="quote-character-left">"</div> */}
								<div className="quote-text">{`" ${text} "`}</div>
								{/* <div className="quote-character-right">"</div> */}
							</div>
							<div className="quote-by-container">
								<div className="quote-by-name">{`- ${name}`}</div>
								{/* <div className="quote-by-url">{url}</div> */}
							</div>
						</div>
					);
				})}
			</Carousel>
		</div>
	);
};
